@import "vars_aurelio";

.form-group {
    color: $light-text-color;
}

.form-control {
    padding: 15px;
    border: 1px solid lighten($border-color, 5%);
    font-size: 16px;
    border-radius: 7px;
    height: auto;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, .04);
    -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, .04);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, .04);
    -webkit-transition: border-color 0.3s ease-in-out;
    transition: border-color 0.3s ease-in-out;
    color: $light-text-color;
    background-color: #fff;
}

.form-control::placeholder {
    color: darken($border-color, 10%);
}

.form-control:hover {
    border-color: darken($border-color, 5%);
}

.form-control:focus {
    border-color: darken($border-color, 10%);
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, .04);
    -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, .04);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, .04);
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: transparent;
}

.has-errors .form-control,
.has-errors .form-control:focus {
    border: 1px solid lighten($danger-color, 15%);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-errors .checkbox,
.has-errors .checkbox-inline,
.has-errors .control-label,
.has-errors .help-block,
.has-errors .radio,
.has-errors .radio-inline,
.has-errors .checkbox label,
.has-errors .checkbox-inline label,
.has-errors .radio label,
.has-errors .radio-inline label {
    color: $danger-color;
    text-align: right;
}
.alert { 
    border: 1px solid transparent;
    border-radius: 7px;
    font-weight: 400;
}
.alert-success {
    color: #fff;
    background-color: $main-color;
    i { 
        color: #fff !important;
    }
}

.alert-danger {
    color: #fff;
    background-color: $danger-color;
    a {
        color: #fff;
        font-weight: 600;
        text-decoration: underline;
    }
}
.alert-warning {
    color: $light-text-color;
    background-color: $warning-color;
}


.close,
.close:hover {
    font-weight: 300;
    opacity: 1;
    text-shadow: none;
    color: #fff;
}

.text-danger {
    color: $danger-color;
}

.input-group-addon {
    border: none;
    border-radius: 5px;
    background-color: $background-light;
    color: $light-text-color;
}

.settings form .input-group-addon {
    border: 1px solid lighten($border-color, 5%);
    border-right: none;
}

.form-group {
    margin-bottom: 10px;
}

.help-block {
    font-size: 10px;
    margin-top: -18px;
    width: 100%;
    text-align: right;
    padding-right: 6px;
    padding-bottom: 4px;
    color: darken($border-color, 15%);
}

.form-group label {
    font-weight: normal;
    font-size: 16px;
}

a,
a:hover {
    color: $main-color;
    text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:focus-visible {
outline: 0;
}

select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    display: block;
    border: none;
    padding: 10px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border-radius: 7px;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%235e5e5e%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
        linear-gradient(to bottom, $background-light 0%, $background-light 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
    color: $light-text-color;
    font-weight: normal;
}

select::-ms-expand {
    display: none;
}

select:focus {
    outline: none;
}

select option {
    font-weight: normal;
}

select:disabled,
select[aria-disabled="true"] {
    color: graytext;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
        linear-gradient(to bottom, $semi-light-background-color 0%, $semi-light-background-color 100%);
}

select:disabled:hover,
select[aria-disabled="true"] {
    border-color: #aaa;
}

.panel select {
    border: 1px solid lighten($border-color, 5%);
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, .04);
    -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, .04);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, .04);
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%235e5e5e%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
        linear-gradient(to bottom, #fff 0%, #fff 100%);
}

.popover {
    background-image: -webkit-linear-gradient(top, lighten($border-color, 10%) 14.13%, #fff 97.78%);
    background-image: linear-gradient(180deg, lighten($border-color, 10%) 14.13%, #fff 97.78%);
    color: $light-text-color;
    padding: 14px;
    font-size: 14px;
    padding: 5px;
    border: 1px solid $border-color;
}

.popover .arrow {
    display: none
}

.sign-up .form-control,
#login-section .form-control,
#registrationForm .form-control {
    padding: 25px 15px;
}
.help-block:empty {
    display: none;
}
.control-label {
    font-weight: 500;
    color: $dark-text-color;

}

.control-label.check {
    top: -3px;
    position: relative;
}

.mandatory-sign {
    font-weight: 500;
    color: $main-color;
    font-size: 22px;
    line-height: 1px;
    padding-top: 14px; 
}

.panel-primary.mandatory { 
    -webkit-box-shadow: 0px 0 8px rgba(86, 185, 241, 0.5);
    -moz-box-shadow: 0px 0 8px rgba(86, 185, 241, 0.5);
    box-shadow: 0px 0 8px rgba(86, 185, 241, 0.5);
    border: 1px solid transparent;
}


.popover {
  max-width: 100%;

  .booked-player-details {
    .person {
      white-space: nowrap;
    }

    .friend {
        color: $main-color;
    }

    .booked-rentals {
      display: inline;

      @media (max-width: 992px) {
        display: block;
      }

      .booked-rental {
        display: inline-block;
      }
    }
  }
}
