$font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
$main-color: #56b9f1;
$secondary-color: #6c7277;
$dark-text-color: #172b4d;
$light-text-color: #606875;
$warning-color: #ffc107;
$danger-color: #f02a34;
$background-light: #f4f5f6;
$background-extra-light: lighten($background-light, 3%);
$semi-light-background-color: #cad0d4;
$block-color: #f7555e;
$icon-color: #898e92;
$border-color: #dadce0;
$logo-secondary-color: #61DFA6;